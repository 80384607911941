.info {
    @include before;
    background: #FAD7BD;

    @include media-breakpoint-up(lg) {
        padding: 64px 0 24px;
    }

    @include media-breakpoint-down(md) {
        padding: 48px 0 136px;
    }

    &:before {
        @extend %zi-1;
        background: url(../img/bg/info.png) no-repeat right bottom rgba(0,0,0,0);

        @include media-breakpoint-up(lg) {
            @include pos(-50px,0);
            right: 0;
            bottom: 0;   
        }
    
        @include media-breakpoint-down(md) {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 360px;
            background-size: auto 100%;
        }
    }

    &.info_light {
        background: #CAFDFF;

        @include media-breakpoint-down(lg) {
            padding-bottom: 300px;
        }

        @include media-breakpoint-down(md) {
            padding-top: 32px;
        }

        &:before {
            background: url(../img/bg/info-light.png) no-repeat left bottom rgba(0,0,0,0);

            @include media-breakpoint-down(lg) {
                @include pos(auto,0);
                right: 0;
                bottom: 0;
                height: 376px;
                background-size: auto 100%;
            }
        }

        .info__title {
            @include media-breakpoint-down(md) {
                @include fs-lh(48px,52px);
                @include offset(0 0 24px,0);
            }
        }
    }

    &.info_mb {
        margin-bottom: 40px;
    }
}

.info__wrapper {
    @extend %zi-2;

    @include media-breakpoint-up(xl) {
        width: (100%/12*8);
        margin: 0 0 0 (100%/12);
    }

    @include media-breakpoint-only(lg) {
        width: (100%/12*9);
    }

    &.info__wrapper_right {
        @include media-breakpoint-up(xl) {
            width: (100%/12*9);
            margin: 0 0 0 (100%/12*3);
        }

        @include media-breakpoint-only(lg) {
            width: 100%;
        }
    }
}

.info__title {
    @extend %title-default;

    @include media-breakpoint-up(lg) {
        @include fs-lh(82px,86px);
        margin: 0 0 32px;
    }

    @include media-breakpoint-down(md) {
        @include fs-lh(36px,48px);
        @include offset(0 0 48px,0 24px);
    }
}

.info__content {
    @include fs-lh(18px,32px);
    letter-spacing: 0.03em;
    margin: 0 0 48px;

    &.info__content_flex {
        @extend %d-flex;
        margin-bottom: 0;
    }

    p {
        text-indent: 32px;
        margin: 0 0 8px;
    }

    ul {
        padding: 0;
    }

    ol {
        padding: 0;
    }

    li {
        @include before;
        @include offset(0 0 8px,0 0 0 60px);
        list-style: none;

        &:before {
            @include pos(16px,0);
            @include wh(52px,2px);
            background: $color__brand_optional;
        }
    }

    ol {
        li {
            @extend %flex-item;
            @include offset(0 0 16px,0);

            &:before {
                display: none;
            }
        }
    }
}

.info__col {
    @include media-breakpoint-up(md) {
        @include fw(100%/12*5);
        margin: 0 0 0 (100%/12);
    }

    @include media-breakpoint-down(sm) {
        @include fw(100%);
        padding: 0 0 0 48px !important;
    }
}

.info__footer {
    @include fs-lh(14px,18px);
    letter-spacing: 0.03em;

    @include media-breakpoint-up(lg) {
        width: (100%/12*8);
    }

    @include media-breakpoint-down(md) {
        width: (100%/12*6);
    }
}