.card {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    transition: all .3s ease;
    border: 1px solid #E5E5E5;

    @include media-breakpoint-down(sm) {
        margin: 0 0 28px;
    }

    &.card_main {
        background: #fff;
        border-radius: 16px;
        border: 1px solid #E5E5E5;
        box-shadow: 1px 1px 16px 0 rgba(0, 43, 138, 0.15);

        .card__picture {
            margin: 0 0 16px;
        }

        .card__header {
            margin: 0 0 60px;
        }

        .card__body {
            @include media-breakpoint-up(md) {
                padding: 0 32px 32px;
            }

            @include media-breakpoint-down(sm) {
                padding: 0 16px 32px;
            }
        }

        .card__body_hidden {
            display: none;
        }

        .card__title {
            @include font(24px,700);
            line-height: 30px;
            display: block;
            margin: 0 0 12px;
            text-transform: none;
            font-family: $font-family_content;
        }

        .card__text {
            @include fs-lh(16px,22px);
            letter-spacing: 0;
            color: #002B89;
        }

        .card__row {
            color: #5A68D3;
        }

        .card__price {
            @include fs-lh(24px,36px);
            margin-right: 0;
            color: $btn__colored_top;
            font-family: $font-family_content;

            .price {
                color: $btn__colored_top;
            }

            &.card__price_colored {
                color: $color__brand_default;

                .price {
                    color: $color__brand_default;
                }
            }
        }

        .price {
            @include fs-lh(36px,36px);
        }
    }

    &.card_jc {
        justify-content: center;
    }

    &.card_hovered {
        &:hover {
            @include media-breakpoint-up(xl) {
                background: #F4F4F4;
            }
    
            .card__picture {
                &:before {
                    @include media-breakpoint-up(xl) {
                        opacity: 1;
                    }
                }
            }
    
            .card__body_visible {
                @include media-breakpoint-up(xl) {
                    opacity: 0;
                    pointer-events: none;
                }
            }
    
            .card__body_hidden {
                @include media-breakpoint-up(xl) {
                    opacity: 1;
                    pointer-events: all;
                }
            }
        }
    }

    &.card_offset {
        @include media-breakpoint-up(xl) {
            margin: 0 40px 28px;
        }
    }

    &.card_trial {
        @include before;
        overflow: inherit;

        &:before {
            @include pos(-3px,-3px);
            right: -3px;
            bottom: -3px;
            z-index: 1;
            opacity: 0;
            transition: all .3s ease;
            border-radius: 16px;
            background: #a780ff;
            background: -moz-linear-gradient(-45deg,  #a780ff 0%, #5b31f3 50%, #501cc3 100%);
            background: -webkit-linear-gradient(-45deg,  #a780ff 0%,#5b31f3 50%,#501cc3 100%);
            background: linear-gradient(135deg,  #a780ff 0%,#5b31f3 50%,#501cc3 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a780ff', endColorstr='#501cc3',GradientType=1 );
        }

        .card__picture {
            @extend %zi-2;
            margin-bottom: 0;

            .img-fluid {
                border-radius: 14px 14px 0 0;
            }
        }

        .card__overlay {
            border-radius: 14px 14px 0 0;
        }

        .card__body {
            @extend %zi-2;
            overflow: hidden;
            padding-top: 16px;
            border-radius: 0 0 14px 14px;
            background: #fff;
        }

        &:hover {
            &:before {
                @include media-breakpoint-up(lg) {
                    opacity: 1;
                }
            }

            .card__overlay {
                @include media-breakpoint-up(lg) {
                    margin: 0;
                    opacity: 1;
                    transform: translateY(0);
                }
            }

            .card__body {
                .btn {
                    @include media-breakpoint-up(lg) {
                        box-shadow: none;
                    }

                    &:not(.btn_inverse) {
                        @include media-breakpoint-up(lg) {
                            color: $btn__colored_top;
                        }

                        &:before {
                            @include media-breakpoint-up(lg) {
                                @include pos(2px,2px);
                                right: 2px;
                                bottom: 2px;
                                opacity: 1;
                                z-index: 1;
                                width: auto;
                                transform: none;
                                border-radius: 6px;
                            }
                        }
                    }
                }
            }
        }
    }

    &.card_current {
        &:before {
            @include media-breakpoint-down(md) {
                opacity: 1;
            }
        }

        .card__overlay {
            @include media-breakpoint-down(md) {
                margin: 0;
                opacity: 1;
                transform: translateY(0);
            }
        }

        .card__body {
            .btn {
                @include media-breakpoint-down(md) {
                    box-shadow: none;
                }

                &:not(.btn_inverse) {
                    @include media-breakpoint-down(md) {
                        color: $btn__colored_top;
                    }

                    &:before {
                        @include media-breakpoint-down(md) {
                            @include pos(2px,2px);
                            right: 2px;
                            bottom: 2px;
                            opacity: 1;
                            z-index: 1;
                            width: auto;
                            transform: none;
                            border-radius: 6px;
                        }
                    }
                }
            }
        }
    }
}

.card__picture {
    @include before;
    margin: 0 0 8px;
    display: block;
    text-align: center;

    &:before {
        @include pos(0,0);
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: 2;
        transition: all .3s ease;
        background: rgba(7, 6, 87, 0.4);
    }
}

.card__overlay {
    @include pos(0,0);
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    transform: translateY(100%);
    transition: all .3s ease;
    background: $color__brand_light;

    @include media-breakpoint-up(sm) {
        padding: 16px 32px;
    }

    @include media-breakpoint-only(xs) {
        padding: 16px;
    }
}

.card__offer {
    display: block;
    margin: 0 0 20px;
    text-align: left;
    text-transform: uppercase;
    color: $btn__colored_bottom;

    @include media-breakpoint-up(sm) {
        @include fs-lh(36px,48px);
    }

    @include media-breakpoint-only(xs) {
        @include fs-lh(24px,30px);
    }
}

.card__label {
    @extend %flex-column;
    @extend %title-default;
    @include wh(56px,56px);
    position: absolute;
    top: 10px;
    right: 14px;
    z-index: 1;
    color: #fff;
    align-items: center;
    justify-content: center;
    background: url(../img/bg/icon-plan-sale-sm.svg) no-repeat scroll 50% rgba(0,0,0,0);
}

.card__body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    transition: all .3s ease;

    @include media-breakpoint-up(md) {
        padding: 0 28px 22px;
    }

    @include media-breakpoint-down(sm) {
        padding: 0 20px 18px;
    }

    &.card__body_visible {
    }

    &.card__body_hidden {
        pointer-events: none;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: 2;
        padding-top: 24px;
        background: #F4F4F4;

        .card__header {
            margin: 0 0 20px;
        }

        .card__readmore {
            margin: 0 0 20px;
        }
    }
}

.card__header {
    flex-grow: 1;
    margin: 0 0 14px;
}

.card__title {
    @extend %title-default;
    color: $color__brand_optional;
}

.card__intro {
    @extend %flex-item;
    @include fs-lh(14px,18px);
    letter-spacing: 0.05em;
    color: #070657;
}

.card__date {
    @include fs-lh(18px,24px);
    letter-spacing: 0.03em;
    color: $color__brand_default;
}

.card__footer {
    //text-align: center;
}

.card__row {
    @extend %flex-item;
    margin: 0 0 8px;
    justify-content: space-between;
}

.card__inner {
    margin: 0 0 14px;

    &.card__inner_mb {
        margin: 0 0 18px;
    }
}

.card__text {
    line-height: 18px;
    letter-spacing: 0.05em;

    .card__readmore {
        @include fs-lh(16px,22px);
        font-weight: 700;
        display: inline;
        color: #002B89;
        letter-spacing: 0;
        margin: 0 0 0 15px;
    }
}

.card__subtitle {
    @include fs-lh(14px,18px);
    letter-spacing: 0.05em;

    &.card__subtitle_lg {
        @include font(18px,700);
        margin: 0 0 16px;
        text-align: center;
        letter-spacing: 0;
        text-transform: uppercase;
        color: $color__brand_default;
    }
}

.card__price {
    @extend %title-default;
    @include inline-block;
    margin: 0 32px 0 0;
    color: #070657;

    &.card__price_colored {
        color: $color__brand_optional;
    }

    &.card__price_old {
        @include before;
        color: #C4C4C4;

        &:before {
            @include pos(10px,0);
            right: 0;
            height: 2px;
            background: #9D9D9D;
            transform: rotate(15deg);
            transform-origin: left top;
        }
    }
}

.card__readmore {
    @extend %flex-item;
    @include font(14px,500);
    line-height: 16px;
    letter-spacing: 0.05em;
    margin: 0 0 14px;
}