.up {
    @include inline-block;
    position: absolute;
    left: 50%;
    bottom: 14px;
    z-index: 3;
    margin: 0 0 0 -26px;
    text-align: center;
}

.up__icon {
    @include wh(12px,18px);
    display: block;
    margin: 0 auto 10px;
}

.up__title {
    @include fs-lh(14px,16px);
    display: block;
    font-weight: 500;
    letter-spacing: 0.05em;
    color: $color__brand_optional;
}