.play {
    @include pos(50%,50%);
    @include inline-block;
    margin: -75px 0 0 -75px;
    cursor: pointer;
    pointer-events: none;

    &.play_hidden {
        opacity: 0;
    }
}

.play__text {
    @include wh(150px,150px);
    transform-origin: center center;
    animation: circlepit 10s linear infinite;
}

.play__btn {
    @include pos(50%,50%);
    @include wh(110px,110px);
    margin: -55px 0 0 -55px;
}

@keyframes circlepit{
    0%{ transform:rotate(0deg) } to { transform:rotate(1turn) }
}