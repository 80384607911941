.features {
    @extend %d-flex;
    align-items: center;
    font-family: $font-family_content;

    @include media-breakpoint-up(lg) {
        justify-content: space-around;
    }
}

.features__item {
    @extend %flex-item;

    @include media-breakpoint-up(xl) {
        @include fw(100%/12*3);
    }

    @include media-breakpoint-only(lg) {
        @include fw(100%/12*4);
        padding: 0 16px 0 0;
    }

    @include media-breakpoint-down(md) {
        @include fw(100%);
        margin: 0 0 32px;
    }
}

.features__item-icon {
    @include fw(96px);
    @include wh(96px,96px);
    margin: 0 24px 0 0;

    @include media-breakpoint-only(lg) {
        margin: 0 16px 0 0;
    }
}

.features__item-title {
    @include fs-lh(18px,26px);
    color: $color__brand_default;
}