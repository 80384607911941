.offer {
    @include before;
    overflow: hidden;
    background: #ece3fb;
    background: -moz-linear-gradient(left,  #ece3fb 0%, #e0f8ff 100%);
    background: -webkit-linear-gradient(left,  #ece3fb 0%,#e0f8ff 100%);
    background: linear-gradient(to right,  #ece3fb 0%,#e0f8ff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ece3fb', endColorstr='#e0f8ff',GradientType=1 );

    @include media-breakpoint-up(md) {
        @include offset(0 0 80px,80px 0);
    }

    @include media-breakpoint-down(sm) {
        @include offset(0 0 48px,58px 0 44px);
    }

    &:before {
        right: 0;
        bottom: 0;
        z-index: 1;
        background: url(../img/bg/offer.svg) no-repeat scroll right top rgba(0,0,0,0);

        @include media-breakpoint-up(md) {
            @include pos(0,0);
        }

        @include media-breakpoint-down(sm) {
            position: absolute;
            left: 0;
            height: 500px;
            background: url(../img/bg/offer-mobile.png) no-repeat scroll right bottom rgba(0,0,0,0);
            background-size: auto 100%;
        }
    }

    &.offer_main {
        font-family: $font-family_content;

        .offer__title {
            font-family: $font-family_content;
        }

        .offer__price {
            @include fs-lh(48px,86px);
            font-family: $font-family_content;

            &.offer__price_old {
                @include fs-lh(24px,30px);
            }

            .price {
                @include fs-lh(60px,86px);
            }
        }
    }

    &.offer_light {
        background: #cafdff;
        background: -moz-linear-gradient(left,  #cafdff 0%, #cafff2 100%);
        background: -webkit-linear-gradient(left,  #cafdff 0%,#cafff2 100%);
        background: linear-gradient(to right,  #cafdff 0%,#cafff2 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cafdff', endColorstr='#cafff2',GradientType=1 );

        .offer__title {
            color: $color__brand_optional;
        }

        .offer__price_colored {
            color: $color__brand_optional;
        }
    }

    &.offer_bg {
        background: #cafdff;
    }

    &.offer_flex {
        @extend %zi-2;
        @extend %d-flex;
        align-items: center;
        margin: 0 0 32px;

        @include media-breakpoint-up(lg) {
            padding: 24px 32px;
        }

        @include media-breakpoint-between(sm,md) {
            padding: 16px;
        }

        @include media-breakpoint-only(xs) {
            padding: 16px 8px;
        }

        &:before {
            display: none;
        }

        .offer__col {
            @include media-breakpoint-up(xl) {
                @include fw(100%/12*4);
                margin-right: 0;
            }

            @include media-breakpoint-down(lg) {
                @include offset(0,0);
            }

            @include media-breakpoint-only(lg) {
                @include fw(100%/12*6);
            }

            @media (min-width:1200px) and (max-width:1440px) {
                @include fw(100%/12*6);
                margin: 0 0 16px;
            }

            @include media-breakpoint-down(md) {
                @include fw(100%);
                margin: 0 0 16px;
            }

            &:last-child {
                @include media-breakpoint-down(md) {
                    margin: 0;
                }
            }

            &.offer__col_top {
                @include media-breakpoint-down(lg) {
                    @include fw(100%);
                    margin: 0 0 16px;
                }

                @media (min-width:1200px) and (max-width:1440px) {
                    @include fw(100%);
                }
            }
        }
    }
}

.offer__wrapper {
    @extend %zi-2;
    @extend %d-flex;
    @extend %container;

    @include media-breakpoint-up(xl) {
        @include px(130px);
    }
}

.offer__col {
    @include media-breakpoint-up(lg) {
        @include fw(100%/12*5);
        margin: 0 (100%/12) 0 0;
    }

    @include media-breakpoint-only(md) {
        @include fw(100%/12*6);
        padding: 0 36px 0 0;
    }

    @include media-breakpoint-down(sm) {
        @include fw(100%);
    }

    &.offer__col_top {
        @include media-breakpoint-up(lg) {
            @include fw(100%/12*6);
        }

        @include media-breakpoint-down(sm) {
            margin: 0 0 48px;
        }

        & + .offer__col {
            @include media-breakpoint-up(lg) {
                margin-right: 0;
            }
        }
    }
}

.offer__title {
    @extend %title-content;
    margin: 0 0 32px;

    @include media-breakpoint-up(md) {
        @include fs-lh(36px,48px);
    }

    @include media-breakpoint-down(sm) {
        //@include fs-lh(48px,52px);
    }

    &.offer__title_sm {
        @include fs-lh(24px,30px);
        color: $color__brand_optional;
        margin-bottom: 0;
    }

    &.offer__title_desktop {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &.offer__title_mobile {
        @include media-breakpoint-up(md) {
            display: none;
        }
    }
}

.offer__row {
    @extend %d-flex;
    align-items: center;
    margin: 0 0 16px;

    &.offer__row_mb {
        @include media-breakpoint-down(sm) {
            margin: 0 0 40px;
        }
    }

    .offer__subtitle {
        @include fw(100%);
    }
}

.offer__feature {
    @include fw(80px);
    @include wh(80px,80px);
    overflow: hidden;
    position: relative;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid #fff;
    box-shadow: 1px 1px 15px 0 rgba(40, 15, 112, 0.2);

    @include media-breakpoint-up(md) {
    margin: 0 0 0 -16px;
    }

    @include media-breakpoint-down(sm) {
        order: 2;
        margin: 0 0 0 -24px;
    }

    &.offer__feature_sale {
        @extend %flex-item;
        @extend %title-default;
        @include fs-lh(36px,48px);
        justify-content: center;
        color: $color__brand_optional;
        box-shadow: 1px 1px 4px 0 rgba(27, 43, 128, 0.25);

        &:after {
            content: '%';
            @include fs-lh(24px,30px);
        }
    }

    &.offer__feature_ml {
        @include media-breakpoint-up(md) {
            margin-left: 22px;
        }

        @include media-breakpoint-down(sm) {
            order: 1;
            margin: 0 10px 0 0;
            z-index: 1 !important;
        }
    }

    &.offer__feature_sm {
        @include fw(60px);
        @include wh(60px,60px);
        @include fs-lh(24px,30px);
    }

    &:first-child {
        margin-left: 0;
    }

    @for $i from 1 through 5 {
        &:nth-child(#{$i}) {
            z-index: -$i;
        }
    }
}

.offer__price {
    @extend %title-default;

    @include media-breakpoint-up(md) {
        @include fs-lh(60px,86px);
        margin: 0 64px 0 0;
    }

    @include media-breakpoint-down(sm) {
        @include fs-lh(48px,52px);
        margin: 0 32px 0 0;
    }

    &:last-child {
        margin-right: 0;
    }

    &.offer__price_old {
        @include before;
        @include fs-lh(24px,30px);
        color: #C4C4C4;

        &:before {
            @include pos(10px,0);
            right: 0;
            height: 2px;
            background: #6D59FE;
            transform: rotate(15deg);
            transform-origin: left top;
        }
    }

    &.offer__price_sm {
        @include fs-lh(36px,48px);
    }

    &.offer__price_xs {
        @include fs-lh(24px,30px);
    }
}

.offer__subtitle {
    @include fs-lh(14px,18px);
    color: $color__brand_optional;

    &.offer__subtitle_dark {
        letter-spacing: 0.05em;
        color: $color__text_default;
    }
}

.offer__footer {
    @extend %flex-item;
    @include fs-lh(14px,18px);
    color: $color__brand_optional;

    @include media-breakpoint-down(sm) {
        text-align: center;
        justify-content: center;
    }
}

.offer__date {
    @include fs-lh(18px,24px);
    margin: 0 0 0 8px;
    color: $color__brand_default;
}