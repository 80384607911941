.grid {
    @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(3,1fr);
    }

    @include media-breakpoint-up(md) {
        display: grid;
        grid-template-rows: 1fr;
    }

    @include media-breakpoint-only(md) {
        grid-template-columns: repeat(2,1fr);
    }

    &.grid_mingap {
        grid-gap: 28px;
    }

    &.grid_4col {
        grid-column-gap: 60px;

        @include media-breakpoint-up(xl) {
            grid-template-columns: repeat(4,1fr);
        }

        @include media-breakpoint-between(md,lg) {
            grid-template-columns: repeat(2,1fr);
            grid-row-gap: 40px;
        }
    }

    &.grid_3col {
        grid-column-gap: 28px;
        grid-row-gap: 24px;

        @include media-breakpoint-up(xl) {
            grid-template-columns: repeat(3,1fr);
        }
    
        @include media-breakpoint-between(md,lg) {
            grid-template-columns: repeat(2,1fr);
        }
    }

    &.grid_maxgap {
        grid-column-gap: 40px;
        grid-row-gap: 40px;
    }
}