.details {
    color: $color__brand_default;

    @include media-breakpoint-up(md) {
        display: flex;
        flex-wrap: wrap;
        padding: 0 8px 54px;
    }

    @include media-breakpoint-down(sm) {
        padding: 0 16px 32px;
    }
}

.details__sidebar {
    @include media-breakpoint-up(lg) {
        @include fw(100%/12*3);
    }

    @include media-breakpoint-only(md) {
        @include fw(100%/12*4);
    }

    @include media-breakpoint-up(md) {
        margin: 0 0 54px;
        margin-left: (100%/12);
    }

    @include media-breakpoint-down(sm) {
        margin: 0 0 32px;
    }
}

.details__list {
    @include offset(0,0);
}

.details__list-item {
    @include fs-lh(24px,36px);
    margin: 0 0 2px;
    list-style: none;

    &:last-child {
        margin-bottom: 0;
    }

    .font-weight-bold {
        @include fs-lh(48px,60px);
        font-family: $font-family_bold;
    }
}

.details__list-title {
    @include fs-lh(36px,38px);
    font-weight: bold;
}

.details__content {
    @include fs-lh(18px,24px);

    @include media-breakpoint-up(md) {
        @include fw(100%/12*6);
        margin: 0 0 54px;
        margin-left: (100%/12);
    }

    @include media-breakpoint-down(sm) {
        margin: 0 0 32px;
    }
}

.details__title {
    @include font(24px,700);
    margin: 0 0 4px;
}

.details__text {
    margin: 0 0 16px;

    &:last-child {
        margin-bottom: 0;
    }
}

.details__footer {
    @include fw(100%);
    text-align: center;
}